import * as Case from 'case';
import { logEvent } from 'firebase/analytics';

import { MerchantDetailsProp } from 'components/Explore/interface';
import MerchantsInfo from 'components/MerchantsInfo';
import { analytics } from 'config/firebase.config';
import { useUserCoordinates } from 'hooks/useUserCoordinates';
import { OurFavoritesMapProps, OurFavoritesProps } from './interface';

import * as S from './styles';
import { useEffect, useState } from 'react';
import api from 'services/api';

const getMerchantById = (
  merchantData: MerchantDetailsProp[],
  id: string
): MerchantDetailsProp | undefined => {
  return merchantData.find((merchant) => merchant.id === id);
};

const servicesMap: { [key: string]: string } = {
  takeout: 'Pick-up',
  voucher: 'Online Purchase',
};

const getServices = (status: string[] | undefined) => {
  const mappedStatus = status?.map((item: string) => servicesMap[item] || item);
  const joinedStatus = mappedStatus?.join(' • ');
  return Case.capital(Case.lower(joinedStatus || ''));
};

const renderFavorites = ({
  merchants,
  merchantData,
  userCoordinates,
}: OurFavoritesMapProps) =>
  merchants.map((item: string) => {
    const merchantDetails = getMerchantById(merchantData, item);

    return (
      merchantDetails && (
        <S.FavoritesItem
          key={merchantDetails?.id}
          onClick={() => {
            logEvent(analytics!, 'merchant_view_featured', {
              name: merchantDetails?.name,
              id: merchantDetails?.id,
              userCoordinates,
            });
          }}
        >
          <MerchantsInfo
            key={merchantDetails.id}
            merchantDetails={merchantDetails}
          />
        </S.FavoritesItem>
      )
    );
  });

const OurFavorites = ({
  favoriteMerchants,
  merchantData,
}: OurFavoritesProps) => {
  const userCoordinates = useUserCoordinates(false);
  const [merchantsState, setMerchantsState] = useState(merchantData);

  useEffect(() => {
    const nextShift = merchantData.reduce((prev, current) => {
      return new Date(prev.nextShift) < new Date(current.nextShift)
        ? prev
        : current;
    });

    const milisecondsTillNextShift =
      new Date(nextShift.nextShift).getTime() - new Date().getTime();
    getMerchants();
    setTimeout(() => {
      getMerchants();
    }, milisecondsTillNextShift);
  }, [merchantData]);

  const getMerchants = async () => {
    const { data } = await api.get('/explore');

    console.log('merchants', data.merchantsDetails);

    setMerchantsState(data.merchantsDetails);
  };

  return (
    <S.Container>
      <S.Title>Our Favorites</S.Title>

      <S.OurFavoritesContainer>
        {renderFavorites({
          merchants: favoriteMerchants.merchants,
          merchantData: merchantsState,
          userCoordinates,
        })}
      </S.OurFavoritesContainer>
    </S.Container>
  );
};

export default OurFavorites;
